<template>
  <div>
    <v-container class="text_google">
      <base-material-card
        icon="mdi-clipboard-text"
        title="การประเมินครูผู้ช่วย"
        class="px-5 py-3"
      >
        <hr />
        <v-simple-table>
          <tbody>
            <tr>
              <td class="text-center font-weight-bold">
                วิทยาลัยเสนอกรรมการประเมิน <br />
                <v-icon
                  color="green"
                  v-if="manage_assistant_teacherIDs.mt_committe_status === 'CF'"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
              </td>
              <td class="text-center">
                <v-btn
                v-if="manage_assistant_teacherIDs.mt_committe_file"
                  rounded
                  outlined
                  color="info"
                  @click="
                    viewpdfmt_committe_file(
                      manage_assistant_teacherIDs.mt_committe_file
                    )
                  "
                  ><v-icon></v-icon> กรรมการประเมิน</v-btn
                >
              </td>
              <td class="text-center font-weight-bold">
                ผลการตรวจสอบจากส่วนกลาง
<br>
                <v-icon
                  color="green"
                  v-if="manage_assistant_teacherIDs.mt_committe_ipaapprove === 'pass'"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >

              </td>

              <td></td>
            </tr>
            <tr>
              <td class="text-center">
                วันเดือนปีที่บรรจุ
                {{ calAppoint.dateApps }}
              </td>
              <td class="text-center">
                จำนวนครูผู้ช่วยในรอบนี้ {{ calAppoint.countAssistants }} คน
              </td>
              <td class="text-center">
                ส่งผลประเมินแล้ว {{ calAppoint.countSuccess }} คน
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-center">
                ครบกำหนดประเมินครั้งที่ 1 วันที่
              </td>
              <td>{{ calAppoint.month6 | moment("D MMMM YYYY") }}</td>
            </tr>
            <tr>
              <td colspan="2" class="text-center">
                ครบกำหนดประเมินครั้งที่ 2 วันที่
              </td>
              <td>{{ calAppoint.month12 | moment("D MMMM YYYY") }}</td>
            </tr>
            <tr>
              <td colspan="2" class="text-center">
                ครบกำหนดประเมินครั้งที่ 3 วันที่
              </td>
              <td>{{ calAppoint.month18 | moment("D MMMM YYYY") }}</td>
            </tr>
            <tr>
              <td colspan="2" class="text-center">
                ครบกำหนดประเมินครั้งที่ 4 วันที่
              </td>
              <td>{{ calAppoint.month24 | moment("D MMMM YYYY") }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <hr />
        <div class="font-weight-bold">
          รายงานการประเมิน
        </div>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="assistant_teachers"
          :search="search"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-center">{{ item.assistant_teacher_times }}</td>
              <td class="text-center">
                {{
                  item.assistant_teacher_datetime
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
              </td>
              <td class="text-center">
                <div v-if="item.assistant_teacher_times <= 2">
                  <v-chip
                    color="success"
                    dark
                    v-if="item.assistant_teacher_score >= 60"
                  >
                    ผ่านการประเมิน</v-chip
                  >
                  <v-chip color="red" dark="" v-else> ไม่ผ่านการประเมิน</v-chip>
                </div>
                <div v-if="item.assistant_teacher_times >= 3">
                  <v-chip
                    color="success"
                    dark
                    v-if="item.assistant_teacher_score >= 70"
                  >
                    ผ่านการประเมิน</v-chip
                  >
                  <v-chip color="red" dark="" v-else> ไม่ผ่านการประเมิน</v-chip>
                </div>
              </td>
              <td class="text-center">
                <div v-if="item.assistant_teacher_status === 'confirm'">
                  ส่งผลคะแนนยังส่วนกลาง กจ.2
                </div>
                <div v-else>
                  อยู่ระหว่างการประเมิน
                </div>
              </td>

              <td class="text-center">
                <div>
                  <v-icon
                    color="success"
                    v-if="item.assistant_teacher_ipacheck === 'Pass'"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  <v-icon
                    color="red"
                    v-else-if="item.assistant_teacher_ipacheck === 'NotPass'"
                    >mdi-close-circle-outline</v-icon
                  >

                  <v-icon color="red" v-else
                    >mdi-checkbox-blank-circle-outline</v-icon
                  >
                </div>
              </td>

              <td class="text-center">
                <div>
                  {{ item.assistant_teacher_ipacomment }}
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </base-material-card>

         <!-- V-model pdfmt_committe_filedialog -->
    <v-layout row justify-center>
      <v-dialog v-model="pdfmt_committe_filedialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRcommitteeAssistantTeach/' + pdf_files"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>
    </v-layout>


    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ApiKey: "HRvec2021",
      loading: true,
      search: "",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      currentPK: null,
      headers: [
        { text: "ครั้งที่", align: "center", value: "assistant_teacher_times" },
        {
          text: "เมื่อวันที่",
          align: "center",
          value: "assistant_teacher_datetime"
        },
        {
          text: "ผลการประเมิน",
          align: "center",
          value: "assistant_teacher_score"
        },
        { text: "สถานะ", align: "center", value: "assistant_teacher_status" },
        {
          text: "การตรวจสอบจากส่วนกลาง",
          align: "center",
          value: "assistant_teacher_ipacheck"
        },
        {
          text: "หมายเหตุ",
          align: "center",
          value: "assistant_teacher_ipacomment"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      pagination: {},
      assistant_teachers: [],
      users: [],
      calAppoint: [],
      manage_assistant_teacherIDs: [],
      pdf_files: [],
      pdfmt_committe_filedialog: false
    };
  },

  async mounted() {
    await this.personnelQuery();
    await this.assistant_teachersQueryAll();
    await this.periosTimeassissment();
    await this.manage_assistant_teacherID();
  },

  methods: {
    async viewpdfmt_committe_file(mt_committe_file) {
      this.pdf_files = mt_committe_file;
      this.pdfmt_committe_filedialog = true;
    },

    async personnelQuery() {
      this.loading = true;
      let useression = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: useression.id_card
        })
        .finally(() => (this.loading = false));
      this.users = result.data;
      
    },

    async assistant_teachersQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_id_card: this.users.id_card
        })
        .finally(() => (this.loading = false));
      this.assistant_teachers = result.data;
      
    },

    async periosTimeassissment() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        appoin_day: this.users.appoin_day,
        appoin_month: this.users.appoin_month,
        appoin_year: this.users.appoin_year,
        calendar_admin: "Ok"
      });
      this.calAppoint = result.data;
    },

    async manage_assistant_teacherID() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id_card: this.users.id_card
      });
      this.manage_assistant_teacherIDs = result.data;
    }
  },

  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
</script>
<style>
.v-data-table thead th {
  font-size: 16px !important;
}
</style>
